import React from "react"
import ChartistGraph from 'react-chartist';
import Ample from "../components/ample/ample"
import {formatCurrency, validateNumericInput} from "../utils"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import GenericPopover from "../components/general/genericpopover"
import OptionPicker from "../components/optionpicker"
import moment from 'moment'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableHead } from "@mui/material";
import ChartParams from "../chartparams/optionpricer.json";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default class OptionPricerPage extends React.Component {
  
  SortCol = {Strike:0, Price:1}
  SortDir = {Asc:0,Desc:1}

  state = {
    strike: "4710",
    iv: "0.1627",
    t : ".0002", days_to_expiration:"5",hours_to_expiration:"0",
    interest_rate: "0.011", //"0.0085",
    option_type:"1",
    underlying_price_start: "4700",
    underlying_price_end: "4720",
    underlying_price_step: "1",
    resultsVisible: false,
    pricedata:[],
    backdropActive:false,
    chainLoaderAnchorElement:null,
    instructionsExpanded:true,
    optionpickerPopoverRef:null
  }
  
  componentDidMount() {
    this.setState({
      sort_col:this.SortCol.Strike,
      sort_dir:this.SortDir.Price,
      chainLoaderAnchorElement:document.getElementById("iv"),
      optionpickerPopoverRef: React.createRef()
    })
  }

  //#region Form validation and events
  validateInputField(field,allowZero=false,allowNeg=false) {
    const val = this.state[field].toString()
    return validateNumericInput(val, allowZero,allowNeg)
  }

  isFormValid() { 
    return ( this.validateInputField('t') && this.validateInputField('strike') && this.validateInputField('iv') && this.validateInputField('days_to_expiration',true) && this.validateInputField('hours_to_expiration',true) 
            && this.validateInputField('underlying_price_start',true) && this.validateInputField('underlying_price_end',false) && this.validateInputField('underlying_price_step',false,true))
  }
  resetForm = event => {
    this.setState({resultsVisible:false})
  }

  handleTextInput = (field,value) => {
    this.setState({[field]:value});
  }

  //#endregion

  //#region Calculations
  /*
    computeDTE
      user will have specified the number of days and hours until expiration
      calculate t, which is the % of a year represtented by that time period
  */
  computeDTE() {
    const input_days = parseFloat(this.state.days_to_expiration)
    const input_hours = parseFloat(this.state.hours_to_expiration)
    const hours_in_year = 365.0 * 24

    let total_hours = (input_days * 24) + input_hours
    let new_t = total_hours/hours_in_year
    this.setState({t:new_t})
  }
  /*
    computeTfromExpiry
      Set the input fields hours_to_expiration and days_to_expiration to match the given expiration date
      and then calculate t, which is the % of a year represtented by the time period between now and then
  */
  computeTfromExpiry(expiry) 
  {
    expiry=moment(expiry).add(16, 'hours'); //???
    const now = moment();

    //calculate the number of minutes between the expiration and now
    const days = expiry.diff(now, 'days');
    const hours = expiry.diff(now, 'hours') - (days*24);
    const minutes = expiry.diff(now, 'minutes');

    //calculate the percentage of a year that this time period represents
    const minutes_in_year = 365.0 * 24 * 60;
    const t = minutes / minutes_in_year;

    this.setState({
      hours_to_expiration: hours,
      days_to_expiration: days,
      t:t
    }
    )
  }

  /*
    computeRangeFromChain
      some option chains contain many more strikes than we wish to show to the user
      given the lowest and highest strike values in the chain, along with the strike
      that the user has selected, determine a stop and start range that will better fit
  */
  computeRangeFromChain(chain_bottom, chain_top, selected_strike,step) {
    const chain_length = chain_top - chain_bottom;
     //default to showing 20% of the entire chain
    let range_width = chain_length * .1
   
    let range_start = Math.floor(selected_strike - range_width);
    if (range_start<chain_bottom) range_start = chain_bottom;
    range_start = range_start - (range_start % step)

    let range_stop = Math.ceil(selected_strike + range_width);
    if (range_stop>chain_top) range_stop = chain_top;
    range_stop = range_stop - (range_stop % step)

    return { range_start:range_start,range_stop:range_stop }
  }
  //#endregion

  componentDidUpdate(prevProps,prevState) {
    if (prevState.days_to_expiration!==this.state.days_to_expiration || prevState.hours_to_expiration!==this.state.hours_to_expiration) {
      //if the inputs have changed but we've *also* seen t change, that means we don't need to recompute t
      if (prevState.t===this.state.t) {
        this.computeDTE() 
      }
    }
  }

  formSubmitted = event => {
    this.requestOptionPriceData();   
  }
  requestOptionPriceData() {
    this.setState({resultsVisible:false,backdropActive:true});
                                
    const url = `${process.env.GATSBY_OPTIONS_API_ENDPOINT}/pricer?strike=${this.state.strike}&iv=${this.state.iv}&t=${this.state.t}&interest_rate=${this.state.interest_rate}&option_type=${this.state.option_type}&underlying_price_start=${this.state.underlying_price_start}&underlying_price_end=${this.state.underlying_price_end}&underlying_price_step=${this.state.underlying_price_step}`
    console.log(url);
    
    fetch(url)
      .then(res => res.json())
      .then((data) =>this.setState({pricedata: data}),()=>this.sortData())
      .then(()=>this.setState({resultsVisible:true,backdropActive:false}))
      .catch(console.log)
  }

  //#region Sorting
  sortData() {
    const col = this.state.sort_col;
    const dir = this.state.sort_dir;
    const data = this.state.pricedata;

    switch (col) {
      case this.SortCol.Strike:
        if (dir === this.SortDir.Asc) {
          data.sort( (a,b) => b.underlying_price - a.underlying_price );
        } else {
          data.sort( (a,b) => a.underlying_price - b.underlying_price );
        }
        
        break;
      default: //this.SortMode.Price:
        if (dir === this.SortDir.Asc) {
          data.sort( (a,b) => b.option_price - a.option_price );
        } else {
          data.sort( (a,b) => a.option_price - b.option_price );
        }
    }
    this.setState({pricedata:data});
  }

  requestSort = (col) => {
    let sort_dir = this.state.sort_dir;
    //if we're clicking the column that's already selected, reverse the sort direction
    if (col === this.state.sort_col) sort_dir = (sort_dir === this.SortDir.Asc) ? this.SortDir.Desc : this.SortDir.Asc;
    this.setState({sort_col:col, sort_dir:sort_dir},()=>this.sortData())    
  }
  //#endregion



  /*
    When the user has selected a new option from the OptionPicker, it will send up a packet with the new strike/iv/range
  */
  handleIncomingStrikeData = strike_data => {
    //determine the range of strikes to make visible, given the length of the chain the user has selected 
    const visible_range = this.computeRangeFromChain(strike_data['min_strike'],strike_data['max_strike'],strike_data['strike'].strike,strike_data['step']);
    //set the T value based on the expiration date the user has selected
    this.computeTfromExpiry(strike_data['expiry']);
    //update state to make it look like the user had selected all these inputs all along
    this.setState(
      {
        strike:strike_data['strike'].strike,
        iv:strike_data['strike'].impliedVolatility,
        underlying_price_start:visible_range['range_start'],
        underlying_price_end:visible_range['range_stop'],
        underlying_price_step:strike_data['step']
      }
    )
  }

  //#region UI rendering methods
  /*
    optionPriceTable
      render the options price data (option price as function of underlying stock price) as a table
  */
  optionPriceTableCard()  {
    let data=this.state.pricedata;
    if (!data || data===null) return <></>;

    return (<Card>
      <CardContent>
        <h2 className="font-medium font-14">Prices</h2>
        <Table width="100%" className="table"  >
              <TableHead>
              <TableRow>
                  <TableCell className="border-top-0" onClick={() => this.requestSort(this.SortCol.Strike)}>Underlying&nbsp;<i className="fas fa-sort"></i></TableCell>
                  <TableCell className="border-top-0" onClick={() => this.requestSort(this.SortCol.Price)}>Option&nbsp;<i className="fas fa-sort"></i></TableCell>
              </TableRow>
              </TableHead>
              <TableBody id="spreadRows">
              {data.map(p=> <TableRow key={p.underlying_price}>
                            <TableCell>{formatCurrency(p.underlying_price)}</TableCell>
                            <TableCell>{formatCurrency(p.option_price)}</TableCell>
                          </TableRow>)}
              </TableBody>
              </Table></CardContent>
      </Card>
      );
  }

  /*
    optionPriceChart
      render the options price data (option price as function of underlying stock price) as a chart
        this uses Chartist to render the chart:
            https://gionkunz.github.io/chartist-js/api-documentation.html
            https://www.npmjs.com/package/react-chartist
  */
  optionPriceChartCard = () => {
    let pricedata=this.state.pricedata;
    if (!pricedata || pricedata===null) return <></>;

    //labels / x-values will be the underlying price
    //series / y-values will be the option price
    var chartdata = { 
      labels: pricedata.map(p=>p.underlying_price),
      series: [pricedata.map(p=>p.option_price)]  //this supports multiple series
    }

    //add a function to the parameters to cause the Y values to display as a currency
    ChartParams.axisY={
      labelInterpolationFnc: function(value) {return   formatCurrency(value)}
    }

    return (
      <Card>
      <CardContent>
        <ChartistGraph data={chartdata} options={ChartParams} type='Line' />
      </CardContent>
      </Card>
    );
  }
  toggleInstructions = () => {
    const expand=(this.state.instructionsExpanded===false); 
    this.setState({instructionsExpanded:expand});
  }
  explainerCard = () => {
    return (
      <Card >
      <CardContent className="font-light"  style={{paddingBottom:0,paddingTop:0}} >
        <Table width={100} sx={{[`& .${tableCellClasses.root}`]: {p: .25,borderBottom: "none"}}}>
          <TableBody>
          <TableRow>
            <TableCell>
              The <b>Option Price Visualizer</b> is a tool which allows you to plot how the price of an option changes with respect to changes in the price of the underlying stock.
            </TableCell>
            <TableCell>
              <IconButton size="inherit" aria-label="show instructions" onClick={()=>this.toggleInstructions()} sx={{transform:(!this.state.instructionsExpanded ? 'rotate(0deg)' : 'rotate(180deg)')}}>
                <ExpandMoreIcon />
              </IconButton>
            </TableCell>
          </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardContent  className="font-light"  style={{paddingBottom:0,paddingTop:"4px"}}>
        <Collapse in={this.state.instructionsExpanded===true}>
        <h2 className="font-medium font-14">Why Use It</h2>
        <p>
        The tool can be helpful in devising trading strategies, where you which to quickly determine what the price of an option will be at a specific point in time (such as the open or close of the stock market), based on the likely range of values of the underlying stock at that point.
        </p>
        
        <h2 className="font-medium font-14">How to Use It</h2>
        <p>
        <u>Configure the option</u>: Enter the <b>Strike</b> price for the option, the <b>Implied Volatility</b> (or <b>IV</b>), and the number of <b>Days</b> and <b>Hours</b> left from now until expiration.
        <br/><i className="fas fa-lightbulb" ></i>&nbsp;Alternatively, press <i className="fas fa-link"></i> to load the <b>Option Picker</b>, which will allow you to select a specific option strike from the available stocks and expiration dates and calculate <b>IV</b> and the <b>Days</b> and <b>Hours</b> to expiration automatically.
        </p>
        
        <p>
        <u>Set the range</u>: Enter the <b>Start</b> and <b>Stop</b> price for the underlying stock. The option price will be calculated for each price in the range. You can enter a <b>Step</b> other than $1, to plot the option price at intervals of less than or several dollars.
        </p>

        <p>
        Press the <i className="fas fa-play-circle  m-r-5"></i> button to generate the plot.
        </p>
          <h2 className="font-medium font-14">How It Works</h2>
        <p>
          The tool utilizes the <OutboundLink href="https://www.investopedia.com/terms/b/blackscholes.asp">Black-Scholes model</OutboundLink> to compute the price of the option for each of the price of the underlying option within the specified range.
        </p>
      </Collapse>
      </CardContent>
      </Card>);
  }
  
  searchCard = () => {
    return (<Card>
      <CardContent>
        <Table style={{minWidth:"400px", maxWidth:"640px"}}  sx={{[`& .${tableCellClasses.root}`]: {p: .25,borderBottom: "none", whiteSpace: "nowrap"}}}>
          <TableBody>
          <TableRow>
          <TableCell >
                <Table>
                  <TableHead>
                  <TableRow><TableCell colSpan="3" ><InputLabel>Option Details:</InputLabel></TableCell></TableRow>
                  </TableHead>
                  <TableBody>
                  <TableRow>
                    <TableCell>
                      <TextField type="number" error={!this.validateInputField('strike')} required={true} style={{width: "85px"}} label="Strike" aria-label="Strike" id="strike" inputProps={{ min: "0", max: "9999", step: ".5" }} value={this.state.strike} onChange={(e)=>this.handleTextInput('strike',e.target.value)} variant="outlined" helperText="Option strike"/>
                    </TableCell>
                    <TableCell>
                      <TextField type="number" error={!this.validateInputField('iv')} style={{width: "100px"}} label="IV" id="iv" aria-label="IV"  inputProps={{ min: "0", max: "1", step: ".0001" }} value={this.state.iv} onChange={(e)=>this.handleTextInput('iv',e.target.value)} variant="outlined" helperText="Implied vol."/>
                    </TableCell>
                    <TableCell>
                      <RadioGroup  value={this.state.option_type} label="Option type" aria-label="Option type" onChange={(e)=>this.handleTextInput('option_type',e.target.value)} name="optionTypeSelector" id="optionTypeSelector">
                      <FormControlLabel  value="1" control={<Radio  />} label="Call"  />
                      <FormControlLabel value="2" control={<Radio />} label="Put" />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
          </TableCell>
          <TableCell>
            <Table>
              <TableHead>
              <TableRow><TableCell colSpan="2"><InputLabel>Time to Expiration:</InputLabel></TableCell></TableRow>
              </TableHead>
              <TableBody>
              <TableRow>
                <TableCell><TextField type="number" error={!this.validateInputField('t') || !this.validateInputField('days_to_expiration',true)} style={{width: "60px"}}  inputProps={{ min: "0", max: "9999", step: "1" }} label="Days" aria-label="Days" id="days_to_expiry" value={this.state.days_to_expiration} onChange={(e)=>this.handleTextInput('days_to_expiration',e.target.value)} variant="outlined"/></TableCell>
                <TableCell><TextField type="number" error={!this.validateInputField('t')  || !this.validateInputField('hours_to_expiration',true)} style={{width: "60px"}}  inputProps={{ min: "0", max: "23", step: "1" }} label="Hours" aria-label="Hours" id="hours_to_expiry" value={this.state.hours_to_expiration} onChange={(e)=>this.handleTextInput('hours_to_expiration',e.target.value)} variant="outlined"/></TableCell>
              </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell >
              <Table>
                <TableHead>
                <TableRow><TableCell colSpan="3"><InputLabel>Underlying Stock's Price Range:</InputLabel></TableCell></TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                  <TableCell><TextField type="number" error={!this.validateInputField('underlying_price_start',true)} style={{width: "85px"}} label="From" aria-label="From" id="underlying_price_start"  inputProps={{ min: "0", max: "9999", step: ".25" }} value={this.state.underlying_price_start} variant="outlined"  onChange={(e)=>this.handleTextInput('underlying_price_start',e.target.value)}/></TableCell>
                  <TableCell><TextField type="number" error={!this.validateInputField('underlying_price_end',false)} style={{width: "85px"}} label="To" aria-label="To" id="underlying_price_end"  inputProps={{ min: "0", max: "9999", step: ".25" }} value={this.state.underlying_price_end} variant="outlined" onChange={(e)=>this.handleTextInput('underlying_price_end',e.target.value)}/></TableCell>
                  <TableCell><TextField type="number" error={!this.validateInputField('underlying_price_step',false,true)} style={{width: "70px"}} label="Step" aria-label="Step" id="underlying_price_step"  inputProps={{ min: "0", max: "999", step: ".25" }} value={this.state.underlying_price_step} variant="outlined" onChange={(e)=>this.handleTextInput('underlying_price_step',e.target.value)} /></TableCell>
                </TableRow>
                </TableBody>
              </Table>
          </TableCell>
          <TableCell>
            <GenericPopover ref={this.state.optionpickerPopoverRef} triggerIcon={<i className="fas fa-link"></i>} callToAction="Load from chain" minWidth="600px" externalAnchorElement={this.state.chainLoaderAnchorElement}>
              <OptionPicker onStrikeSelected={(e)=>{this.handleIncomingStrikeData(e); this.state.optionpickerPopoverRef.current.closePopover(); }} />  
            </GenericPopover>
          </TableCell>
        </TableRow>
          
          
        <TableRow> 
          <TableCell>      
            <button disabled={!this.isFormValid()} id="go" onClick={this.formSubmitted}  type="submit"  className="btn btn-rounded btn-default btn-outline">
                <i className="fas fa-play-circle  m-r-5"></i>Show</button>
              <button disabled={!this.state.resultsVisible} id="clear" onClick={this.resetForm} className="btn btn-rounded btn-default btn-outline" >
                <i className="fas fa-eraser  m-r-5"></i>Clear</button>
          </TableCell>
          </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>);
  }
  //#endregion

  render() {
    let seoDescription = "Tool to plot how the price of an option changes with respect to the underlying stock price";
    return (
    <Ample currPageTitle="Option Price Visualizer"  seoDescription={seoDescription}  location={this.props.location.href}>
      
      <Backdrop style={{zIndex:  1,color: '#fff',}} open={this.state.backdropActive}  >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Grid container spacing={2}>
        
          <Grid item  lg={12}  >
            {this.explainerCard()}
          </Grid>

          <Grid item lg={12} sx={{width:1, justifyContent:'right'}}>
            {this.searchCard()}
          </Grid>

          <Grid item  lg={6} sm={6} xs={12} style={{visibility:(this.state.resultsVisible)?'visible':'hidden'}}>
          {this.optionPriceTableCard()}
          </Grid>

          <Grid item  lg={6} sm={6} xs={12} style={{visibility:(this.state.resultsVisible)?'visible':'hidden'}}>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/chartist.js/latest/chartist.min.css" />
            <script src="//cdn.jsdelivr.net/chartist.js/latest/chartist.min.js"></script>
            {this.optionPriceChartCard()}
          </Grid>
      </Grid>
    </Ample>
    );
  }
}



